<template>
  <div class="container">
    <!-- <van-nav-bar title="中酿KM" right-text="搜索" v-if="showNavBar" fixed @click-right="$router.push('search')"></van-nav-bar> -->
    <!-- $route.path!==('/user') -->
    <div class="my-wrapper" :class="{ noTop: !showNavBar }">
      <keep-alive>
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </div>
    <van-tabbar :route="true" v-model="active">
      <van-tabbar-item to="/" icon="home-o">首页</van-tabbar-item>

      <van-tabbar-item to="/share" icon="add-o">分享</van-tabbar-item>
      <!-- dot="ture"   :dot="hasSystmeMessage==true?'false':'ture'"  -->
      <van-tabbar-item to="/user" icon="user-o"  :badge="getMessageTotalCount()"
        >我的</van-tabbar-item
      >
    </van-tabbar>

    <!-- <DragBall  @click="toNew"><div slot="value">反馈</div></DragBall> -->
  </div>
</template>

<script>
//import DragBall from '../components/dragball'
//Vue.use(dragBall)
import { mapGetters, mapMutations } from "vuex";
import { HasMessage, GetSystemUpdate } from "@/api/systemmessage";

export default {
  name: "layout",
  data() {
    return {
      active: "",
    };
  },

  components: {
    //DragBall,
  },
  computed: {
    showNavBar() {
      return this.$route.path !== "/user";
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapMutations(["updateSystemMessage", "updateHasSystemUpdate"]),
    ...mapGetters(["gethasSystmeMessage","getMessageTotalCount"]),
    async initData() {
      let hasMessage = await HasMessage();
      let systemUpdate = await GetSystemUpdate();
      if (
        hasMessage.data.data != undefined &&
        hasMessage.data.data.length > 0
      ) {
        this.updateSystemMessage(hasMessage.data.data);
      } else {
        this.updateSystemMessage([]);
      }
      if (
        systemUpdate.data.data != undefined &&
        systemUpdate.data.data.length > 0
      ) {
        this.updateHasSystemUpdate(true);
      } else {
        this.updateHasSystemUpdate(false);
      }
    },
    toNew() {
      this.$router.push({ path: "/user/feedback" });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  .my-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 50px;
    box-sizing: border-box;
    &.noTop {
      padding-top: 0;
    }
  }
}
</style>
